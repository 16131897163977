<template>
  <div class="rubbishSort-home"></div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";

var moment = require("moment");
export default {
  name: "rubbishSort-home",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // gloabalCount("", 20, 1);
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.rubbishSort-home {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}
</style>
<style lang="less">
.rubbishSort-home {
}
</style>
